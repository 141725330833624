import { Provider, useSelector } from "react-redux";
import React, { lazy, Suspense } from "react";
import LogRocket from "logrocket";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Loader from "./pages/Loader/loader";
import store from "./store/Store";
import Confetti from "./components/Confetti/CustomConfetti";
// import storage from "./services/storage";
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window?.sessionStorage?.getItem("retry-lazy-refreshed") || "false"
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window?.sessionStorage?.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window?.sessionStorage?.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
          return window?.location?.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};
const LoginPage = lazy(() => lazyRetry(() => import("./pages/LoginPage")));
const ForgotPassword = lazy(() =>
  lazyRetry(() => import("./pages/ForgotPassword"))
);
const ResetEmail = lazy(() => lazyRetry(() => import("./pages/ResetEmail")));
const CreateUser = lazy(() =>
  lazyRetry(() => import("./components/CreateUser/CreateUser"))
);
const Home = lazy(() => lazyRetry(() => import("./pages/Home/Home")));
const NewPassword = lazy(() => lazyRetry(() => import("./pages/NewPassword")));
const ContinuePage = lazy(() =>
  lazyRetry(() => import("./pages/ContinuePage"))
);
const EditProfile = lazy(() =>
  lazyRetry(() => import("./pages/EditProfile/EditProfile"))
);
const Games = lazy(() => lazyRetry(() => import("./pages/Game/Games")));
const Lobby = lazy(() => lazyRetry(() => import("./pages/Lobby/Lobby")));
const About = lazy(() => lazyRetry(() => import("./pages/About/About")));
const PrivacyPolicy = lazy(() =>
  lazyRetry(() => import("./pages/Policy/privacyPolicy"))
);
const TermsofUse = lazy(() =>
  lazyRetry(() => import("./pages/Policy/termofUse"))
);
const PromoTerms = lazy(() =>
  lazyRetry(() => import("./pages/Policy/promoTerms"))
);
// const About = lazy(() => lazyRetry(() => import("./pages/About/About")));
const Contact = lazy(() =>
  lazyRetry(() => import("./pages/ContactUs/Contact"))
);
const SettingsHome = lazy(() =>
  lazyRetry(() => import("./pages/Settings/SettingsHome"))
);
const ProtectedRoutes = lazy(() =>
  lazyRetry(() => import("./router/Protected"))
);
const Redirect = lazy(() => lazyRetry(() => import("./router/Redirect")));
const ScrollTop = lazy(() => lazyRetry(() => import("./components/ScrollTop")));
const BillingHistory = lazy(() =>
  lazyRetry(() => import("./pages/BillingHistory/BillingHistory"))
);
const CashoutHistory = lazy(() =>
  lazyRetry(() => import("./pages/CashoutHistory/CashoutHistory"))
);
const ViewAllNotifications = lazy(() =>
  lazyRetry(() =>
    import("./components/Layout/Header/Notifications/ViewAllNotifications")
  )
);
const SearchResults = lazy(() =>
  lazyRetry(() => import("./pages/SearchResults/SearchResults"))
);
// const GamesTest = lazy(() => lazyRetry(() => import("./pages/Game/GamesTest")));
const AllGames = lazy(() =>
  lazyRetry(() => import("./pages/AllGames/AllGames"))
);
const AllGamesFilter = lazy(() =>
  lazyRetry(() => import("./pages/AllGames/AllGamesFilter"))
);
const Support = lazy(() => lazyRetry(() => import("./pages/Support/Support")));
const SocketConnection = lazy(() =>
  lazyRetry(() => import("./components/SocketConnection"))
);
const Jackpot = lazy(() => lazyRetry(() => import("./pages/Jackpot/jackpot")));
const SignupAlter = lazy(() => lazyRetry(() => import("./pages/SignupAlter")));
const JackpotTimerFunctionality = lazy(() =>
  lazyRetry(() => import("./components/JackpotTimerFunctionality"))
);
const VerifyIdentity = lazy(() =>
  lazyRetry(() => import("./pages/VerifyIdentity/VerifyIdentity"))
);
const Jumio = lazy(() =>
  lazyRetry(() => import("./pages/Jumio/Jumio"))
);
const NotificationDetail = lazy(() => lazyRetry(() => import("./pages/NotificationDetail")));
const KycInfo = lazy(() => lazyRetry(() => import("./pages/KycInfo/KycInfo")));


// logo-rocket
LogRocket.init("ddft1a/lionsden");
function App() {
  // const { coins, timeLeft, status } = useSelector((state) => state.jackpot);
  return (
    <>
    {/* // <Provider store={store}> */}
    {/* {status === 'won' && <Confetti />} */}
    {/* <Confetti /> */}
      <SocketConnection />
      <JackpotTimerFunctionality />
      <Suspense fallback={<Loader />}>
        <Router>
          <ScrollTop />
          <Routes>
            <Route
              exact
              path="/login"
              element={
                <Redirect>
                  <LoginPage />
                </Redirect>
              }
            />
            <Route
              exact
              path="/forgot-password"
              element={
                <Redirect>
                  <ForgotPassword />
                </Redirect>
              }
            />
            <Route
              exact
              path="/resend-email"
              element={
                <Redirect>
                  <ResetEmail />
                </Redirect>
              }
            />
            <Route
              exact
              path="/new-password/:id"
              element={
                <Redirect>
                  <NewPassword />
                </Redirect>
              }
            />
            <Route
              exact
              path="/continue-password"
              element={
                <Redirect>
                  <ContinuePage />
                </Redirect>
              }
            />
            <Route
              path="/"
              element={
                <Redirect>
                  <Home />
                </Redirect>
              }
            />
            <Route
              path="/edit-profile"
              element={
                <ProtectedRoutes>
                  <EditProfile />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/games"
              element={
                <ProtectedRoutes>
                  <Games />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/lobby"
              element={
                <ProtectedRoutes>
                  <Lobby />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/jackpot"
              element={
                <ProtectedRoutes>
                  <Jackpot />
                </ProtectedRoutes>
              }
            />
            <Route path="/about" element={<About />} />
            <Route path="/kyc-info" element={<KycInfo />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/use-policy-terms" element={<TermsofUse />} />
            <Route path="/promo-policy-terms" element={<PromoTerms />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/support" element={<Support />} />
            <Route
              path="/settings"
              element={
                <ProtectedRoutes>
                  <SettingsHome />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/billing-history"
              element={
                <ProtectedRoutes>
                  <BillingHistory />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/cashout-history"
              element={
                <ProtectedRoutes>
                  <CashoutHistory />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/verify"
              element={
                <ProtectedRoutes>
                  <VerifyIdentity />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/kyc-jumio"
              element={
                <ProtectedRoutes>
                  <Jumio />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/view-notifications"
              element={
                <ProtectedRoutes>
                  <ViewAllNotifications />
                </ProtectedRoutes>
              }
            />
            <Route path="/search" element={<SearchResults />} />
            {/* <Route path="/test" element={<GamesTest />} /> */}
            <Route path="/all-games" element={<AllGames />} />
            <Route path="/all-games-filter" element={<AllGamesFilter />} />
            <Route
              exact
              path="/signup"
              element={
                <Redirect>
                  <SignupAlter />
                </Redirect>
              }
            />
            <Route
              exact
              path="/email-signup"
              element={
                <Redirect>
                  <CreateUser />
                </Redirect>
              }
            />
            <Route
              exact
              path="/:code"
              element={
                <Redirect>
                  <CreateUser />
                </Redirect>
              }
            />
             <Route
              path="/notification/:id"
              element={
                <ProtectedRoutes>
                  <NotificationDetail />
                </ProtectedRoutes>
              }
            />
          </Routes>
        </Router>
      </Suspense>
    {/* // </Provider> */}
    </>
  );
}

export default App;
